<template>
  <div class="image-gallery">
    <div class="main-image-container">
      <el-image
        style="width: 100%; height: 360px"
        :src="list[activeIndex]"
        :preview-src-list="list"
      >
      </el-image>
    </div>
    <div class="thumbnail-container">
      <div class="left"  @click="listToLeft">
        <em class="el-icon-arrow-left"></em>
      </div>
      <div class="centerBox">
        <div
          class="listBox"
          style="position: absolute; top: 0; left: 0"
          ref="imgListBox"
        >
          <img
            v-for="(item, index) in list"
            :key="index"
            @click="updateActiveIndex(index)"
            :class="{ active: activeIndex === index, items: true }"
            :src="item"
          />
        </div>
      </div>

      <div class="right" @click="listToRight">
        <em class="el-icon-arrow-right"></em>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "image",
  props: {
    imgLIst: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activeIndex: 0,
    };
  },
  computed: {
    list() {
      let list = this.imgLIst.map(
        (item) => "/api/file/displayResource?objectName=" + item
      );
      return list;
    },
  },
  methods: {
    updateActiveIndex(index) {
      this.activeIndex = index;
    },
    listToLeft() {
      if(this.activeIndex<=0){
        return
      }
      let lefts = Number(this.$refs.imgListBox.style.left.slice(0, -2));
      lefts += 125;
      this.$refs.imgListBox.style.left = lefts + "px";
      this.activeIndex-=1
    },
    listToRight() {
      if(this.activeIndex>=this.imgLIst.length-1){
        this.activeIndex = 0
        this.$refs.imgListBox.style.left = 0;
        return
      }
      let lefts = Number(this.$refs.imgListBox.style.left.slice(0, -2));
      lefts -= 125;
      this.$refs.imgListBox.style.left = lefts + "px";
      this.activeIndex+=1
    },
  },
};
</script>

<style lang="less" scoped>
.image-gallery {
  width: 680px;
  height: 480px;
  .thumbnail-container {
    width: 680px;
    height: 100px;
    display: flex;
    align-items: center;
    .left {
      width: 27px;
      height: 96px;
      line-height: 96px;
      text-align: center;
      font-size: 20px;
      background-color: #00000045;
      color: #fff;
      cursor: pointer;
    }
    .right {
      width: 27px;
      height: 96px;
      line-height: 96px;
      text-align: center;
      color: #fff;
      font-size: 20px;
      background-color: #00000045;
      cursor: pointer;
    }
    .centerBox {
      position: relative;
      width: 624px;
      height: 96px;
      margin-left: 4px;
      overflow-x: auto;
      .listBox {
        display: flex;
        align-items: center;
        height: 96px;
        .items {
          display: block;
          box-sizing: border-box;
          width: 115px;
          height: 96px;
          margin-right: 10px;
        }
        .active {
          border: 1px solid #0dac65;
        }
      }
    }
  }
}
</style>
