import request from "./request";

// 判断是否有出价人
export function haveBidder(data) {
  return request({
    url: "/system/AuctionHall/haveBidder",
    method: "get",
    params: data,
  });
}

// 获取详情
export function hallInfo(data) {
  return request({
    url: "/system/AuctionHall/hallInfo",
    method: "get",
    params: data,
  });
}

// 竞买记录
export function queryBidRecord(data) {
  return request({
    url: "/system/AuctionItem/queryBidRecord",
    method: "get",
    params: data,
  });
}