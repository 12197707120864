import request from "./request";

// 获取竞拍列表
export function getAuctionItemList(data) {
  return request({
    url: "/system/AuctionItem/list",
    method: "get",
    params: data,
  });
}

// 获取已结束列表
export function getHistoryList(data) {
  return request({
    url: "/system/AuctionItem/historyList",
    method: "get",
    params: data,
  });
}

// 获取详情
export function getDetail(data) {
  return request({
    url: "/system/AuctionItem/detail",
    method: "post",
    data,
  });
}

// 设置提醒
export function reminder(data) {
  return request({
    url: "/system/AuctionItem/reminder",
    method: "post",
    data,
  });
}
// 取消梯形
export function cancelReminder(data) {
  return request({
    url: "/system/AuctionItem/cancelReminder",
    method: "post",
    data,
  });
}

// 公告详情
export function viewBidAnnouncement(params) {
  return request({
    url: "/system/AuctionItem/viewBidAnnouncement",
    method: "get",
    params,
  });
}
// 公告列表
export function bidAnnouncementList(params) {
  return request({
    url: "/system/AuctionItem/bidAnnouncementList",
    method: "get",
    params,
  });
}

// 竞拍详情支付接口
export function depositPayment(data) {
  return request({
    url: "/client/transaction/depositPayment",
    method: "post",
    data,
  });
}

// 报名之前查看该企业是否有资质
export function haveAuthentication(data) {
  return request({
    url: "/system/manuQualifications/haveAuthentication",
    method: "get",
    params: data,
  });
}
